
























































import { mapGetters } from 'vuex'
import Vue from 'vue'

enum ENotificationType {
  LOCKED_MAP = 'locked_map',
  EDIT_SITE = 'edit_site',
  MAPS_AND_ZONES = 'maps_and_zones',
  PLANNING_TOOL = 'planning_tool',
  ALERT = 'alert'
}

enum EZoneType {
  ALERT = 'alert',
  DISRUPT = 'disrupt',
  LABEL = 'label'
}
interface IShownOnceItems {
  locked_map?: boolean
  edit_site?: boolean
  maps_and_zones?: boolean
  planning_tool?: boolean
  zoneTypeItems: {
    alert?: boolean
    disrupt?: boolean
    label?: boolean
  }
}
interface ISnackbarHintData {
  snackbar: boolean
  shownOnce: IShownOnceItems
  notificationType: string
  zoneType: string
}
export default Vue.extend({
  name: 'SnackbarHint',
  components: {},
  props: [],
  data: (): ISnackbarHintData => ({
    snackbar: false,
    shownOnce: {
      zoneTypeItems: {}
    },

    notificationType: '',
    zoneType: ''
  }),
  computed: {
    ...mapGetters('users', ['isAdmin']),
  },
  mounted(): void {
    this.$bus.$on('SNACKBAR/EMPTY_ZONE_TYPE_ENABLED', (zoneType: EZoneType) => {
      if (
        this.$route.name !== 'Zones' &&
        !this.shownOnce.zoneTypeItems[zoneType]
      ) {
        this.zoneType = zoneType
        this.snackbar = true
        this.shownOnce.zoneTypeItems[zoneType] = true
      }
    })

    this.$bus.$on('SNACKBAR/LOCKED_MAP', () => {
      if (!this.shownOnce.locked_map) {
        this.shownOnce.locked_map = this.snackbar = true
        this.notificationType = ENotificationType.LOCKED_MAP
      }
    })

    this.$bus.$on('SNACKBAR/EDIT_SITE', () => {
      this.snackbar = true
      this.notificationType = ENotificationType.EDIT_SITE
    })

    this.$bus.$on('SNACKBAR/MAPS_AND_ZONES', () => {
      this.snackbar = true
      this.notificationType = ENotificationType.MAPS_AND_ZONES
    })

    this.$bus.$on('SNACKBAR/PLANNING_TOOL', () => {
      this.snackbar = true
      this.notificationType = ENotificationType.PLANNING_TOOL
    })

    this.$bus.$on('SNACKBAR/ALERTS', () => {
      this.snackbar = true
      this.notificationType = ENotificationType.ALERT
    })
  },
  beforeDestroy() {
    this.$bus.$off('SNACKBAR/EMPTY_ZONE_TYPE_ENABLED')
    this.$bus.$off('SNACKBAR/LOCKED_MAP')
    this.$bus.$off('SNACKBAR/EDIT_SITE')
    this.$bus.$off('SNACKBAR/MAPS_AND_ZONES')
    this.$bus.$off('SNACKBAR/PLANNING_TOOL')
    this.$bus.$off('SNACKBAR/ALERTS')
  },
  watch: {
    $route() {
      this.snackbar = false
    }
  }
})
